import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'

import Layout from '../Layout'
import SEO from '../components/SEO'

import AsideImage from '../components/AsideImage/AsideImage'
import Button from '../components/Button/Button'

const IndexPage = ({ data }) => (
  <Layout
    header="Gabinet Stomatologiczny"
    headerBold="Glossy Dental"
    homePage
    asideContent={[
      <AsideImage
        key="asideImage"
        srcImage={data.coverPhoto.childImageSharp.fluid}
      >
        <Button to="https://iqdental.app/rejestracja-wizyty/6b3cd188-16dc-42ad-91ae-4a073eb8795a/new">
          Umów wizytę
        </Button>
      </AsideImage>,
    ]}
  >
    <SEO title="Gabinet Stomatologiczny Glossy Dental | Stomatolog Dentysta Nadarzyn" />
    <p>
      Dbamy o piękny i zdrowy uśmiech pacjenta. Nie jesteśmy po to aby oceniać,
      ale po to aby leczyć. Posiadamy własną pracownię protetyczną.
    </p>
    <p>Nasz gabinet dentystyczny mieści się w miejscowości Nadarzyn.</p>
    <p>Świadczymy usługi z zakresu:</p>
    <ul>
      <li>
        <Link to="uslugi/stomatologia-zachowawcza-i-estetyczna">
          Stomatologi zachowawczej i estetycznej
        </Link>
      </li>
      <li>
        <Link to="uslugi/endodoncja">Endodoncji (leczenie kanałowe)</Link>
      </li>
      <li>
        <Link to="uslugi/protetyka">Nowoczesnej protetyki</Link>
      </li>
      <li>
        <Link to="uslugi/wybielanie-zebow">Wybielania zębów</Link>
      </li>
      <li>
        <Link to="uslugi/chirurgia-stomatologiczna">
          Chirurgii stomatologicznej
        </Link>
      </li>
      <li>
        <Link to="uslugi/ortodoncja">Ortodoncji</Link>
      </li>
      <li>
        <Link to="uslugi/zabiegi-higieniczne">Zabiegów higienicznych</Link>
      </li>
    </ul>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    coverPhoto: file(relativePath: { eq: "image4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
